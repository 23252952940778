import React, { useEffect, useState } from 'react';
import EventBlock from './EventBlock';
import type { CustomFlowbiteTheme } from 'flowbite-react';
import { Button, Card, Datepicker, Flowbite, Label, Modal, Tabs, TextInput, Spinner } from 'flowbite-react';
import { GoPlus } from "react-icons/go";
import { MdEvent, MdSettings } from "react-icons/md";
import { createEvent, getAllEvents, logout } from './firebase';

const customTabsTheme: CustomFlowbiteTheme = {
    tabs: {
        tablist: {
            styles: {
                underline: "bg-white",
            }
        }
    }
};

export interface IEventData {
    name: string;
    program: string;
    code: string;
    time: string;
    date: string;
    signedin_count: Number;
}

const AdminPage: React.FC = () => {
    const [loading, setLoading] = useState(true);
    const [createEventModal, setCreateEventModal] = useState(false);
    const [events, setEvents] = useState<IEventData[]>([]);
    const [eventCData, setEventCData] = useState<IEventData>({
        name: '',
        program: '',
        code: '',
        time: '',
        date: new Date().toLocaleDateString(),
        signedin_count: 0
    });

    useEffect(() => {
        getAllEvents().then((out) => {
            setEvents(out);
            setLoading(false);
        }).catch((error) => {
            console.error('Error fetching events:', error);
            setLoading(false);
        });
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = e.target;
        setEventCData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    return (
        <Flowbite theme={{theme: customTabsTheme}} >
            <div className="mb-4 text-white text-5xl flex items-center justify-between">
                <h1 className=''>Admin Page</h1>
                {loading && (
                    <Spinner size="lg" />
                )}
            </div>
            {!loading && (
                <Tabs style='underline'>
                    <Tabs.Item active title='Events' icon={MdEvent}>
                    <div className='grid grid-cols-4 gap-5 auto-rows-auto'>
                            {events.map((event, index) => (
                                <EventBlock key={index} eventData={event} />
                            ))}
                            <Button color="light" onClick={() => setCreateEventModal(true)} className="flex items-center justify-center gap-2">
                                <GoPlus className="text-xl" />
                                <span>Add New Event</span>
                            </Button>
                        </div>
                    </Tabs.Item>
                    <Tabs.Item title='Settings' icon={MdSettings}>
                        <div className='flex justify-center items-center'>
                            <Button color="failure" onClick={() => logout()}>Log Out</Button>
                        </div>
                    </Tabs.Item>
                </Tabs>
            )}
            <Modal show={createEventModal} onClose={() => setCreateEventModal(false)}>
                <Modal.Header>Create an Event</Modal.Header>
                <Modal.Body>
                    <div className="space-y-6">
                        <p className="text-base leading-relaxed text-gray-500">
                            Instructions: Use this form to create a new event. Participants can then use the generated Attendance Code to sign in.
                        </p>
                        <Label htmlFor="eventName" value="Name" />
                        <TextInput id="eventName" value={eventCData.name} onChange={handleInputChange} name="name" type="text" />

                        <Label htmlFor="eventProgram" value="Program" />
                        <TextInput id="eventProgram" value={eventCData.program} onChange={handleInputChange} name="program" type="text" />

                        <Label htmlFor="eventTime" value="Time (ex. 8:00am-9:00am)" />
                        <TextInput id="eventTime" value={eventCData.time} onChange={handleInputChange} name="time" type="text" />

                        <Label htmlFor="eventDate" value="Date" />
                        <Datepicker id="eventDate" inline onSelectedDateChanged={(date) => setEventCData(prevData => ({
                            ...prevData,
                            date: date.toLocaleDateString(),
                        }))} name='date' />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => {
                        createEvent(eventCData);
                        setCreateEventModal(false);
                    }}>Create Event</Button>
                    <Button color="gray" onClick={() => setCreateEventModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Flowbite>
    );
};

export default AdminPage;
