// firebase.ts
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { DocumentData, addDoc, collection, count, doc, getCountFromServer, getDoc, getDocs, getFirestore, setDoc,  } from 'firebase/firestore'
import { getAuth, GoogleAuthProvider } from "firebase/auth"
import { IEventData } from "./AdminPage";

const firebaseConfig = {
    apiKey: "AIzaSyB3j0gKgp_9YuglJLY9azaJlAS97HFQ3n4",
    authDomain: "bullis-student-tutors.firebaseapp.com",
    projectId: "bullis-student-tutors",
    storageBucket: "bullis-student-tutors.appspot.com",
    messagingSenderId: "585917947635",
    appId: "1:585917947635:web:2065118dbf4bc8468be186",
    measurementId: "G-CE18DS86T9"
  };

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth()
const provider = new GoogleAuthProvider()

const db = getFirestore(app)


interface IAttendance {
    firstName: string;
    lastName: string;
    code: string;
}
const logout = () => {
    auth.signOut()
}
const submitAForm = async (formData: IAttendance): Promise<Number> => {
    formData.code = formData.code.toUpperCase()
    try {
        // Check if the event with the provided code exists
        
        const eventDocRef = doc(db, "events", formData.code);
        const eventDocSnap = await getDoc(eventDocRef);
        
        if (eventDocSnap.exists()) {
            // Event exists, add the attendance data to Firestore
            await setDoc(doc(db, "events", formData.code, "attendance", formData.firstName + formData.lastName), {
                ...formData,
                time: new Date().toLocaleString()
            });
            console.log('Form Save sent successfully!');
            return 1;
        } else {
            // Event does not exist, handle the error
            console.error('Event with code', formData.code, 'does not exist.');
            return -1;
        }
    } catch (error) {
        console.error('Error creating Form Save:', error);
        return -2;
    }
};

function generateRandomCode() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let code = '';
    for (let i = 0; i < 6; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        code += characters[randomIndex];
    }
    return code;
}


const createEvent = async (eventData: IEventData): Promise<void> => {
    eventData.code = generateRandomCode()
    try {
        // Add the event data to Firestore
        await setDoc(doc(db, 'events', eventData.code), eventData);
        console.log('Event created successfully!');
    } catch (error) {
        console.error('Error creating event:', error);
        throw error;
    }
};
const getAttendance = async (code: string): Promise<DocumentData[]> => {
    try {
        const querySnapshot = await getDocs(collection(db,'events', code, "attendance"))
        let aDocs = []
        for await (const doc of querySnapshot.docs) {
            aDocs.push(doc.data())
        }
        return aDocs
    } catch (error) {
        console.log('Error getting event:', error);
        return [];
    } 

    
    
}
// Function to get all events
const getAllEvents = async (): Promise<IEventData[]> => {
    try {
        // Get all documents from the 'events' collection
        const querySnapshot = await getDocs(collection(db, 'events'));
        
        // Extract the data from the documents
        const eventsData: IEventData[] = [];

        for await (const doc of querySnapshot.docs) {
            const eventData = doc.data() as IEventData;
            eventData.signedin_count =  (await getCountFromServer(collection(db,'events', eventData.code, "attendance"))).data().count
            
            eventsData.push(eventData)
        }

        return eventsData;
    } catch (error) {
        console.error('Error getting events:', error);
        throw error;
    }
};



export { auth, provider, createEvent, getAllEvents, submitAForm, getAttendance, logout};
