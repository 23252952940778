// App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AdminPage from './AdminPage';
import LoginPage from './LoginPage';
import { auth } from './firebase';
import {useAuthState} from 'react-firebase-hooks/auth';
import './App.css';
import AttendancePage from './AttendancePage';
const App: React.FC = () => {
  const [user, loading, error] = useAuthState(auth);

  return (
    <Router>
    <Routes>
      <Route path="/" element={<AttendancePage />} />
      <Route path="/admin" element={user ? <AdminPage /> : <LoginPage />} />
    </Routes>
  </Router>
  );
};

export default App;
