import React, { useEffect, useState } from 'react';
import { Button, Card, Modal, Table } from 'flowbite-react';
import { IEventData } from './AdminPage';
import {getAttendance} from './firebase'
import { DocumentData } from 'firebase/firestore';

const EventBlock = (props: {eventData: IEventData}) => {
    
  const [attendance, setAttendance] = useState<DocumentData[]>([])
  const [isOpen, setIsOpen] = useState(false);
  const eventData = props.eventData;
  useEffect(() => {
    getAttendance(eventData.code).then((out)=> {
        setAttendance(out)
    });
}, []);

  const toggleModal = () => {

    document.body.style.overflow = isOpen ? 'auto' : 'hidden';
    setIsOpen(!isOpen);
  };
  
  return (
    <>
        <Modal style={{position: 'absolute'}} dismissible show={isOpen} onClose={()=>toggleModal()}>
        <Modal.Header>{eventData.name}</Modal.Header>
        <Modal.Body>
      
        <div className='mb-4 flex items-center justify-between'>
        <h2 className='text-lg text-gray-900 dark:text-gray-800'>{attendance.length} attended</h2>
        </div>
            {/* Render your list items here */}
            <Table striped>
            <Table.Head>
                    <Table.HeadCell>First Name</Table.HeadCell>
                    <Table.HeadCell>Last Name</Table.HeadCell>
                    <Table.HeadCell>Time</Table.HeadCell>
             
            </Table.Head>
            <Table.Body className="divide-y">
            {attendance.map(((a: DocumentData, i)=>{
                return (
                    <Table.Row key={i}>
                        <Table.Cell>{a.firstName}</Table.Cell>
                        <Table.Cell>{a.lastName}</Table.Cell>
                        <Table.Cell>{a.time}</Table.Cell>
                    </Table.Row>
                )
            }))}
            </Table.Body>
            </Table>
            
          
        </Modal.Body>
        <Modal.Footer>
        

          <Button onClick={()=>toggleModal()}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Card className="max-w-sm h-full cursor-pointer hover:bg-gray-100" onClick={toggleModal} >
      <h4 className="text-md leading-none text-gray-900 dark:text-white">{eventData.program}</h4>
        <div className="mb-4 flex items-center justify-between">
          <h4 className="text-xl font-bold leading-none text-gray-900 dark:text-white">{eventData.name}</h4>
     
         
          </div>
        <div className="flow-root">
          <div className="flex items-center space-x-4">
            <div className="min-w-0 flex-1">
              <p className="text-lg font-bold text-gray-900 dark:text-gray-800">Attendance Code</p>
              <p className="text-lg text-gray-900 dark:text-gray-800">{eventData.code}</p>
              <p className="text-lg font-bold text-gray-900 dark:text-gray-800">Date & Time</p>
              <p className="text-lg text-gray-900 dark:text-gray-800">{eventData.date}</p>
              <p className="text-lg text-gray-900 dark:text-gray-800">{eventData.time}</p>
              <p className="text-lg font-bold text-gray-900 dark:text-gray-800">Tutor Stats</p>
              <p className="text-lg text-gray-900 dark:text-gray-800">{String(eventData.signedin_count)} attended</p>
            </div>
          </div>
        </div>
      </Card>

    </>
  );
};

export default EventBlock;
