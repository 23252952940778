import { Alert, Button, DarkThemeToggle, Label, TextInput } from 'flowbite-react';
import React, { useState } from 'react';
import { submitAForm } from './firebase';
import { FaInfoCircle } from 'react-icons/fa'

const AttendancePage: React.FC = () => {
    interface IAttendance {
        firstName: string;
        lastName: string;
        code: string;
    }
    const [submitted, setSubmitted] = useState(false)
    const [codeAlready, setCodeAlready] = useState(false)
    const [formData, setFormData] = useState<IAttendance>({
        firstName: '',
        lastName: '',
        code: '',
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const submitForm = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        setCodeAlready(false)
        setSubmitted(false)
        // Here you can perform any validation or additional processing before submitting
        console.log('Form submitted with data:', formData);
        setFormData({
            firstName: '',
            lastName: '',
            code: '',
        })
        submitAForm(formData).then(status => {
            switch (status) {
                case 1:
                    setSubmitted(true)
                    break;
                case -1:
                    setCodeAlready(true)
                    break;
                default:
                    console.log("Error")
                    break;
            }
        })
        // Call your backend API or perform any required action with the form data
    };
    return (
        <div className="w-full" style={styles.container}>
            {codeAlready && <Alert color="failure" icon={FaInfoCircle} onDismiss={()=>setSubmitted(false)}>Please double check your code, the entered code does not exist.</Alert>}
            {submitted && <Alert color="success" icon={FaInfoCircle} onDismiss={()=>setSubmitted(false)}>Attendance Submitted</Alert>}
            <br />
            <form className="flex flex-col gap-4 p-10 mw600" onSubmit={submitForm}>
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="firstName" value="First Name" style={styles.white_t }/>
                    </div>
                    <TextInput className="white-text" id="firstName" name="firstName" type="text" placeholder="Joe" value={formData.firstName} onChange={handleInputChange} required />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="lastName" value="Last Name" style={styles.white_t} />
                    </div>
                    <TextInput id="lastName" name="lastName" type="text" value={formData.lastName} onChange={handleInputChange} required />
                </div>
                <div>
                    <div className="mb-2 block">
                        <Label htmlFor="code1" value="Attendance Code" style={styles.white_t} />
                    </div>
                    <TextInput id="code1" name="code" type="text" value={formData.code} onChange={handleInputChange} required />
                </div>

                {!submitted && <Button type="submit">Submit</Button>}
            </form>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        width: "100vw"
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px', // Adds spacing between form elements
    },
    white_t: {
        color: 'white'
    },
};


export default AttendancePage;
